@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&family=Noto+Serif:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Iansui&family=Inter&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Iansui&family=Inter&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Montserrat";
}

/* body {
  font-family: "Playfair Display";
} */


/* body {
  font-family: "Iansui";
} */

.alt-serif {
  font-family: "Playfair Display";
}

.scrolltotop {
  z-index: 2147483647 !important;
}

@media (min-width: 1100px) and (min-height: 540px) {
  .linessus {
      display: flex;
  }
}

@media (max-width: 1180px), (max-height: 540px) {
  .linessus {
      display: none;
  }
}

